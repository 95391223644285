/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlankReturnMessageDto } from '../models/BlankReturnMessageDto';
import type { ChangePasswordDto } from '../models/ChangePasswordDto';
import type { InternalDto } from '../models/InternalDto';
import type { LoginDto } from '../models/LoginDto';
import type { PartialTypeClass } from '../models/PartialTypeClass';
import type { PreLoginResultReturnMessageDto } from '../models/PreLoginResultReturnMessageDto';
import type { RegisterDto } from '../models/RegisterDto';
import type { ResetPasswordDto } from '../models/ResetPasswordDto';
import type { SendMailDto } from '../models/SendMailDto';
import type { TokenCountDtoReturnMessageDto } from '../models/TokenCountDtoReturnMessageDto';
import type { TokenDtoReturnMessageDto } from '../models/TokenDtoReturnMessageDto';
import type { UserReturnMessageDto } from '../models/UserReturnMessageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

const baseURL = import.meta.env.VITE_BASE_URL;

export class DefaultService {
    /**
     * 发送邮件
     * @param requestBody
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static sendMailControllerSend(
        requestBody: SendMailDto,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/send-mail/send',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 验证邮件验证码有效性
     * @param email 邮箱
     * @param purpose 验证码用途
     * @param code 验证码
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static sendMailControllerVerify(
        email: string,
        purpose: 'register' | 'resetPassword',
        code: string,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/send-mail/verify',
            query: {
                email: email,
                purpose: purpose,
                code: code,
            },
        });
    }

    /**
     * 登录
     * @param requestBody
     * @returns TokenDtoReturnMessageDto
     * @throws ApiError
     */
    public static loginControllerLogin(
        requestBody: LoginDto,
    ): CancelablePromise<TokenDtoReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 预登录，判断帐号状态
     * @param email 邮箱
     * @returns PreLoginResultReturnMessageDto
     * @throws ApiError
     */
    public static loginControllerPreLogin(
        email: string,
    ): CancelablePromise<PreLoginResultReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/login/login',
            query: {
                email: email,
            },
        });
    }

    /**
     * 重置密码
     * @param requestBody
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static loginControllerResetPassword(
        requestBody: ResetPasswordDto,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 注册
     * @param requestBody
     * @returns UserReturnMessageDto
     * @throws ApiError
     */
    public static loginControllerRegister(
        requestBody: RegisterDto,
    ): CancelablePromise<UserReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 获取自身用户信息
     * @param authorization 用户 Token
     * @returns UserReturnMessageDto
     * @throws ApiError
     */
    public static userCenterControllerGetProfile(
        authorization?: string,
    ): CancelablePromise<UserReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/user-center/profile',
            headers: {
                Authorization: authorization,
            },
        });
    }

    /**
     * 修改用户信息
     * @param requestBody
     * @param authorization 用户 Token
     * @returns any
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static userCenterControllerUpdateProfile(
        requestBody: PartialTypeClass,
        authorization?: string,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/auth/user-center/profile',
            headers: {
                Authorization: authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 修改密码
     * @param requestBody
     * @param authorization 用户 Token
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static userCenterControllerChangePassword(
        requestBody: ChangePasswordDto,
        authorization?: string,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user-center/change-password',
            headers: {
                Authorization: authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 登出
     * @param authorization
     * @param authorization 用户 Token
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static userCenterControllerLogout(
        authorization: string,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/user-center/logout',
            headers: {
                Authorization: authorization,
            },
        });
    }

    /**
     * Paper 端内部验证。
     * @param requestBody
     * @param authorization Internal Token
     * @returns BlankReturnMessageDto
     * @throws ApiError
     */
    public static internalControllerVerify(
        requestBody: InternalDto,
        authorization?: string,
    ): CancelablePromise<BlankReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/internal/verify',
            headers: {
                Authorization: authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 获取剩余 token 数量。
     * @param authorization 用户 Token
     * @returns TokenCountDtoReturnMessageDto
     * @throws ApiError
     */
    public static tokenCountControllerGetTokenCount(
        authorization?: string,
    ): CancelablePromise<TokenCountDtoReturnMessageDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/token-count',
            headers: {
                Authorization: authorization,
            },
        });
    }
}
