import { defineStore } from 'pinia';
import { UserInfoAtStore, TokenNumInfoAtStore } from '@/types/indexPage';
import { DefaultService } from '@/auth-api';

export const useUserStore = defineStore({
    id: 'user', // id必填，且需要唯一
    state: () => {
        return {
            userInfo: {
                email: '',
            },
            tokenNumInfo: {
                gpt35: {
                    count: 0,
                    limit: 0,
                },
                gpt4: {
                    count: 0,
                    limit: 0,
                },
            },
            lang: 'zh',
        };
    },
    actions: {
        updateInfo(userInfo: UserInfoAtStore) {
            this.userInfo = userInfo;
        },
        updateTokenNumInfo(tokenNumInfo: TokenNumInfoAtStore) {
            this.tokenNumInfo = tokenNumInfo;
        },
        loginOut() {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem('token') as string;
                DefaultService.userCenterControllerLogout(token).then(() => {
                    this.userInfo = {
                        email: '',
                    };
                    resolve('success');
                });
            });
        },
        getTokenUseNum() {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem('token') as string;
                DefaultService.tokenCountControllerGetTokenCount(token).then(
                    (res) => {
                        resolve('success');
                    },
                );
            });
        },
    },
});
