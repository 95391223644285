const setFontSize = (minFontSize: number = 12, maxFontSize: number = 16) => {
    // 7.5根据设计稿的横向分辨率/100得来
    var deviceWidth = document.documentElement.clientWidth; // 获取浏览器宽度
    // 获取浏览器宽度
    // if (deviceWidth > 750) {
    //     // deviceWidth = 750;
    //     deviceWidth = 7.5 * 50;
    // } else if (!deviceWidth) {
    //     return;
    // }
    // const fontSize = Math.max(
    //     minFontSize,
    //     Math.min(12 * (deviceWidth / 320), maxFontSize),
    // );
    // document.documentElement.style.fontSize = `${fontSize}px`;
    if (deviceWidth > 750) {
        deviceWidth = 750;
        // deviceWidth = 7.5 * 50;
    }
    document.documentElement.style.fontSize = `${deviceWidth / 7.5}px`;
    //      此时的fontSize大小为50px(375屏幕的时候)

    // 这样子的1rem=font-size的大小
    document.documentElement.addEventListener(
        'touchstart',
        function (event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        },
        false,
    );
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
        'touchend',
        function (event) {
            var now = Date.now();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        },
        false,
    );
};

export default {
    setFontSize,
};
