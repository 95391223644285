/*
 * @Author: uncleyan 343362726@qq.com
 * @Date: 2022-05-17 22:40:23
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-05-27 23:09:35
 * @FilePath: \vite_demo\mydemo\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { DefaultService } from '@/auth-api';
import { useUserStore } from '@/store/user';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Index',
        meta: {
            title: '首页',
            keepAlive: true,
            requireAuth: true,
        },
        component: () => import('@/pages/IndexPage.vue'),
    },
    {
        path: '/reset/password',
        name: 'resetPassword',
        meta: {
            title: '重置密码',
            keepAlive: true,
            requireAuth: true,
        },
        component: () => import('@/pages/resetPassword.vue'),
    },
    {
        path: '/reset/verfication',
        name: 'verfication',
        meta: {
            title: '校验验证码',
            keepAlive: true,
            requireAuth: true,
        },
        component: () => import('@/pages/verificationCode.vue'),
    },
    {
        path: '/reset/change',
        name: 'changePassword',
        meta: {
            title: '更改密码',
            keepAlive: true,
            requireAuth: true,
        },
        component: () => import('@/pages/changePassword.vue'),
    },
    {
        path: '/file/pdfPreview',
        name: 'pdfPreview',
        component: () => import('@/components/filePreview/index.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
    document.getElementById('main-loading-box')!.style = 'display: flex;';
    const userStore = useUserStore();
    const token = localStorage.getItem('token') as string;
    if (Object.keys(userStore.$state.userInfo).length <= 1 && token) {
        DefaultService.userCenterControllerGetProfile(token).then((res) => {
            userStore.updateInfo(res.data);
        });
    }
    DefaultService.tokenCountControllerGetTokenCount(token).then((res) => {
        userStore.updateTokenNumInfo(res.data);
    });

    // if (to.fullPath === '/login') {
    //     next();
    // } else {
    //     const token = localStorage.getItem('token');
    //     if (token) next();
    //     else router.push('/login');
    // }
    next();
});
router.afterEach((to, from) => {
    document.getElementById('main-loading-box')!.style = 'display: none;';
});
export default router;
