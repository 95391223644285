/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SendMailDto = {
    /**
     * 邮箱
     */
    email: string;
    /**
     * 验证码用途
     */
    purpose: SendMailDto.purpose;
};

export namespace SendMailDto {

    /**
     * 验证码用途
     */
    export enum purpose {
        REGISTER = 'register',
        RESET_PASSWORD = 'resetPassword',
    }


}
