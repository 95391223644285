/*
 * @Author: 孙泽严 343362726@qq.com
 * @Date: 2022-05-16 15:58:17
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-05-27 22:22:53
 * @FilePath: \vite-vue3-template\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from '@/router';
import 'element-plus/es/components/message/style/css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import Rem from '@/utils/rem';
import i18n from './locales';
import lottie from 'lottie-web';
import loadingJson from '@/assets/loading.json';

const loadingDom = document.getElementById('lottie-player') as Element;
const loadingAnimation = lottie.loadAnimation({
    container: loadingDom,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    // assetsPath: CDN_URL,
});

// 创建vue实例
const app = createApp(App);

// 配置icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.config.errorHandler = (err) => {
    /* 处理错误 */
    // alert(err);
    console.log(err);
};

// 挂载pinia
app.use(store);
app.use(router);
app.use(i18n);

// 挂载实例
app.mount('#app');
Rem.setFontSize();
